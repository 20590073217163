"use client";

import Card from "@/components/atoms/Card";
import FooterLinks from "@molecules/FooterLinks";
import Image from "next/image";
import styles from "./footer.module.scss";
import cn from "clsx";
import NewsletterForm from "@molecules/NewsletterForm/NewsletterForm";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

export default function Footer({ footerData, isSponsoredCertificates }: { footerData: any , isSponsoredCertificates?: boolean}) {
  
  const fireWebEngage=(source:string)=>{
    window.webengage.track("Social Media Icon Clicked", {
      "Social Media Name":source,
    })
  }

  const GAEventsHandler = () => {
    pushToDataLayer({
      "event": "logo_click",
      'section_name': "footer section",
      'page_type': getGlobalStateValue('pageType') 
    })
  }

  const GAEventsLogoHander = ({ title })=>{
    pushToDataLayer({
      'event':'social_media_interaction',
      'section_name': footerData.footer.SocialMedia.Heading?.text || '',
      'cta_text': title,
      'page_type': getGlobalStateValue('pageType') 
    })
    fireWebEngage(title);
  }

  return (
    <>
      <footer className={cn("container mb-8", styles.root)}>
        <Card shadow={false} extraClasses="px-3 lg:px-6">
          <FooterLinks
            footerLinks={{
              footerLinksOne: footerData?.footerLinksOne,
              footerLinksTwo: footerData?.footerLinksTwo,
              footerLinksThree: footerData?.footerLinksThree,
            }}
          />

          {/* Logo section */}
          <div className={cn("lg:flex lg:justify-between", styles.footerBottomSection)}>
            {footerData?.footer?.Logo?.enabled && (
              <div className={styles.logo}>
                <a onClick={GAEventsHandler} href="/" target={footerData.footer.Logo?.target}>
                  <Image
                    src={footerData?.footer?.Logo?.source?.data[0]?.attributes?.url}
                    alt={footerData?.footer?.Logo?.alt || "amity online"}
                    title="Amity Online"
                    width={200}
                    height={65}
                  />
                </a>
              </div>
            )}

            {/* Middle login section */}
            <div className="md:flex md:items-start md:gap-[25%] lg:flex-col lg:gap-0">
              {/* Contact section */}
              {footerData?.footer?.ContactSection?.enabled ? (
                <div className="mt-12">
                  {isSponsoredCertificates ? <div className={cn("text-blue-950 font-bold font-raleway", styles.heading)}>
                  For Payment Related Issues
                    </div> : footerData.footer.ContactSection.ContactHeading
                      .enabled ? (
                    <div className={cn("text-blue-950 font-bold font-raleway", styles.heading)}>
                      {footerData.footer.ContactSection.ContactHeading.text}
                    </div>
                  ) : null}

                  {isSponsoredCertificates ? (
                    <div className="mt-2 block">
                      <div className={cn("flex items-center", styles.contactBox)}>
                        <a
                          onClick={() => {
                            pushToDataLayer({
                              event: "footer_interaction",
                              cta_text: " payments@amityonline.com",
                              section_name: "For Payment Related Issues.",
                              page_type: getGlobalStateValue("pageType"),
                            });
                          }}
                          href={"mailto:payments@amityonline.com"}
                          className={cn(styles.contactSponsored)}
                        >
                          <svg width={20} height={20} viewBox="0 0 512 512">
                            {/*!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.*/}
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                          </svg>{" "}
                          payments@amityonline.com
                        </a>
                      </div>
                    </div>
                  ) : (
                    footerData?.footer?.ContactSection?.ContactDetails?.map(
                      (contact: any, i: number) => (
                        <div className="mt-2 block" key={i}>
                          <p className={cn(styles.subHeading)}>{contact.title}</p>
                          <div className={cn("flex items-center", styles.contactBox)}>
                            <a
                              onClick={() => {
                                pushToDataLayer({
                                  event: "footer_interaction",
                                  cta_text: contact.title.includes("Student")
                                    ? "Student support toll free number"
                                    : "Admission Toll Free number",
                                  section_name: "Toll free numbers",
                                  page_type: getGlobalStateValue("pageType"),
                                });
                              }}
                              href={`tel:${contact.text}`}
                              className={cn(styles.contact, "headFootSprite")}
                            >
                              {contact.text}
                            </a>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              ) : null}
            </div>

            {/* Newsletter, social media, copyrgiht */}
            <div className="md:flex md:items-start md:gap-[24%] lg:flex-col lg:gap-0">
              {footerData?.footer?.NewsLetter?.enabled ? (
                <div className={cn("flex flex-col basis-full", styles.newsletterBox)}>
                  <div className="mt-7 basis-full lg:mt-12">
                    {footerData.footer.NewsLetter.Heading[0].enabled ? (
                      <div className={styles.heading}>
                        {footerData.footer.NewsLetter.Heading[0].text}
                      </div>
                    ) : null}
                    {footerData.footer.NewsLetter.SubHeading.enabled ? (
                      <div className="mt-2">
                        <p className={styles.subHeading}>
                          {footerData.footer.NewsLetter.SubHeading.text}
                        </p>
                      </div>
                    ) : null}
                  </div>

                  <NewsletterForm data={footerData.footer.NewsLetter} styles={styles} />
                </div>
              ) : null}

              <div className={styles.socialMedia}>
                {footerData?.footer?.SocialMedia?.enabled ? (
                  <>
                    {footerData.footer.SocialMedia.Heading?.enabled ? (
                      <div className={styles.heading}>
                        {footerData.footer.SocialMedia.Heading?.text}
                      </div>
                    ) : null}

                    <div className="mt-2">
                      <div className="inline-grid grid-cols-5 gap-2">
                        {footerData.footer?.SocialMedia?.SocialMediaSource?.map(
                          (item: any, i: number) => (
                            <a
                              href={item?.url || ""}
                              target={item?.target}
                              key={i}
                              onClick={() => GAEventsLogoHander({ title: item?.title })}
                            >
                              <img
                                key={i}
                                src={item?.source?.data?.[0]?.attributes?.url}
                                alt={item?.alt || "amity online"}
                                loading="lazy"
                                title="Amity Online"
                              />
                            </a>
                          )
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                {footerData?.footer?.CopyrightContent?.enabled ? (
                  <div className={cn("mt-2 mb-8 lining-nums", styles.copyright)}>
                    {footerData.footer.CopyrightContent.text}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </footer>
    </>
  );
}
