"use client";

import { useEffect, useState, useContext } from "react";
import { GAEvent, GA_EVENTS } from "@/helpers/gaEvents";
import styles from "./SideButtons.module.scss";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { IModalActions, ModalTypes } from "@/types/modal";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";
import { ModalContext } from "@/context/ModalContext";
import Close from "./Close";


const ToolTipComponent = () => {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);

  const onCloseHandler = () => {
    setIsHeaderHidden(!isHeaderHidden);
  };

  const onMouseOverhandler = () => {
    setIsHeaderHidden(false);
  };

  return (
    <div className={isHeaderHidden ? styles.videoTooltipClose : styles.videoTooltip}>
      <div className={isHeaderHidden ? styles.videoTooltipClose : styles.videoTooltipRightAlign}>
        <span className={styles.closeSpan} onClick={onCloseHandler}>
          <Close />
        </span>
      </div>
      Fast-Track <br /> Apply & Support
    </div>
  );
};

const SideButtons = ({ data, isMobile = false, modalType, pageType, associatedPrograms }) => {
  const { modalDispatch } = useContext(ModalContext);
  const { STICKY_CTA_TEL, STICKY_CTA_WHATSAPP, CALL_CHAT_INTERACTION } = GAEvent;

  const [isVisible, setIsVisible] = useState(false);

  const res = data?.filter((x, i) => x?.enabled && i !== 0);
  
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.location.pathname === "/" || window.location.pathname === "/mba-campus-immersion-online" || pageType === "programDetails" || pageType === "specialization" || window.location.pathname === "/scholarship") {
        if (window.scrollY > 0.7 * window.innerHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleClick = (item) => {
    
    const itemUrl = item?.url || "#";
    if (itemUrl.includes("tel")) {
      // for tel
      const gaData = GA_EVENTS.get(STICKY_CTA_TEL);
      pushToDataLayer(gaData);
      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "call_us",
        section_name: "sticky_sidetray",
        page_type: getGlobalStateValue("pageType"),
      };
      pushToDataLayer(gaData2);

      window.webengage.track("Call Us Clicked",{
        "Section Name": "sticky_sidetray"
      })

    } else if (itemUrl.includes("whatsapp")) {
      // for whatsapp
      const gaData = GA_EVENTS.get(STICKY_CTA_WHATSAPP);
      pushToDataLayer(gaData);

      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "whatsapp_chat",
        section_name: "sticky_sidetray",
        page_type: getGlobalStateValue("pageType"),
      };
      pushToDataLayer(gaData2);
    }
  };

  const renderItemUrl = (itemUrl) => {
    return itemUrl.includes("tel") && !isMobile ? "#" : itemUrl;
  };

  const fireGTOpenCTA = ()  => {
    
    pushToDataLayer({
      "event":"lead_initiate",
      "cta_text": "Apply Now",
      "section_name":"side_widget",
      "header_title": "header_title",
      "page_type": getGlobalStateValue("pageType"),
      "page_url": location.pathname
    });

    updateStateSession({
      sectionTitle: "header_title",
      leadStartingCta: "Apply Now",
      parentSection: "side_widget"
    })

    modalDispatch({
      type: IModalActions.OpenModal,
      data: {
        modalType,
        modalData: { associatedPrograms }
      },
    });
  }
  


  const fireGtEvents = () => {

      modalDispatch({
        type: IModalActions.OpenModal,
        data: {
          modalType: ModalTypes.CallUsModal,
        },
      });

      pushToDataLayer({
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "call_us",
        section_name: "sticky_sidetray",
        page_type: getGlobalStateValue("pageType")
      })

      window.webengage.track("Call Us Clicked", {
        "Section Name": "sticky_sidetray"
      })
  }

  return (
    <>
      <div className={styles.sideBtnRoot}>
        <div className={`sideButtons flex flex-col ${styles.buttonPosition} ${styles.btnWrapper}`}>
          {res?.map((item, i) => (
            <div className={styles.imgSize} key={i}>
              {item.title === "call-button" && !isMobile ? (
                <button
                  onClick={fireGtEvents}
                  className={styles.stickCTAbtn}
                >
                  <img
                    id="call-icon"
                    className={cn("aspect-square", styles.imgIconSize)}
                    src={item?.source?.data?.[0]?.attributes?.url}
                    alt={item?.alt || "call us"}
                    loading="lazy"
                    title="call us"
                  />
                </button>
              ) : (
                <>
                  <a
                    href={renderItemUrl(item?.url) || "#"}
                    onClick={() => handleClick(item)}
                    target={item?.target?.[0] || "_self"}
                  >
                    <img
                      id="wa-icon"
                      className={cn("aspect-square", styles.imgIconSize)}
                      src={item?.source?.data?.[0]?.attributes?.url}
                      alt={item?.alt || "wa-icon"}
                      loading="lazy"
                      title="wa-icon"
                    />
                  </a>

                  {item.title == "whatsapp-link" && <ToolTipComponent />}
                </>
              )}
            </div>
          ))}
          {isVisible && (
            <div className={styles.stickyApplymob}>
              <div onClick={fireGTOpenCTA} className={styles.stickyApplyBtn}>
                APPLY NOW
              </div>
            </div>
          )}
        </div>
      </div>
      {isVisible && (
        <div className={styles.stickyApply}>
          <div onClick={fireGTOpenCTA} className={styles.stickyApplyBtn}>
            APPLY NOW
          </div>
        </div>
      )}
    </>
  );
};
export default SideButtons;
